.admin-layout main {
  display: flex;
}

.admin-layout nav {
  display: flex;
  flex-direction: column;
}

section {
  padding: 0;
}

#admin-main {
  display: grid;
  grid-template-columns: 200px 1fr;
}

// LEFT SIDEBAR
.left-sidebar {
  border-right: 1px solid #e5eaef;
  transition: 0.2s ease-in;
  height: 100%;
  z-index: 11;
  width: 270px;
  position: fixed;
  background-color: var(--background-color);

  &>.admin-logo {
    min-height: 70px;
    padding: 0 24px;
  }

  &>.sidebar-nav {
    overflow-y: auto;
    padding: 0 24px;
    height: calc(100vh - 80px);
    border-radius: 7px;
    margin-top: 24px;

    &>.sidebar-link {
      display: flex;
      white-space: nowrap;
      align-items: center;
      line-height: 25px;
      position: relative;
      margin: 0 0 2px;
      padding: 10px;
      border-radius: 7px;
      gap: 15px;
      text-decoration: none;
      font-weight: 400;

      &.active {
        background-color: #5d87ff;
        color: #fff;
      }

      &:not(.active):hover {
        background-color: rgba(93, 135, 255, 0.1);
        color: #5d87ff;
      }
    }
  }
}

.admin-nav>nav {
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &>a {
    gap: 5px;
    display: flex;
  }
}

.admin-content {
  flex: 1;
  padding: 24px;
  margin-left: 270px;
  position: relative;
}

@media screen and (max-width: 768px) {

  // Mobile
  #admin-main {
    display: block;

    .breadcrumb {
      margin-top: 30px;
    }
  }

  .admin-content {
    margin-left: 0;
    padding: 8px;
  }

  .left-sidebar {
    display: none;
  }

  .left-sidebar.active {
    display: block;
  }
}