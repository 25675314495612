*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
