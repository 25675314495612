/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* Global reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto";
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.container {
  padding-left: 80px;
  padding-right: 80px;
  max-width: 100%;
}

.content {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.justify-content-start {
  justify-content: start;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

img {
  width: 100%;
}

.w-full {
  width: 100%;
}

/* PLAIN List */
ul.plain-list {
  display: flex;
}

ul.plain-list li:not(:first-child) {
  margin-left: 32px;
}

ul.plain-list li:first-child {
  list-style: none;
}

ul {
  padding: 0;
}

ul.list-none {
  list-style: none;
}

.text-center {
  text-align: center;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.app-link {
  font-weight: 500;
  text-decoration: underline;
}

/* Divider */
.hr {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.card {
  padding: 16px;
  border-radius: 0.375rem;
  border: 1px solid #ddd;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

pre {
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  color: #353535;
  direction: ltr;
  font-style: normal;
  min-width: 200px;
  padding: 1em;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}

a.active {
  font-weight: 700;
}

:root,
[data-theme="light"] {
  --bs-blue: #5d87ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #fa896b;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffae1f;
  --bs-green: #13deb9;
  --bs-teal: #20c997;
  --bs-cyan: #539bff;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #2a3547;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f6f9fc;
  --bs-gray-200: #eaeff4;
  --bs-gray-300: #dfe5ef;
  --bs-gray-400: #7c8fac;
  --bs-gray-500: #5a6a85;
  --bs-gray-600: #2a3547;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #5d87ff;
  --bs-secondary: #49beff;
  --bs-success: #13deb9;
  --bs-info: #539bff;
  --bs-warning: #ffae1f;
  --bs-danger: #fa896b;
  --bs-light: #f6f9fc;
  --bs-dark: #2a3547;
  --bs-muted: #5a6a85;
  --bs-indigo: #6610f2;
  --bs-light-primary: #ecf2ff;
  --bs-light-secondary: #e8f7ff;
  --bs-light-info: #ebf3fe;
  --bs-light-success: #e6fffa;
  --bs-light-warning: #fef5e5;
  --bs-light-danger: #fbf2ef;
  --bs-light-indigo: #ebf3fe;
  --bs-dark-light: #2a3547;
  --bs-light-gray: #f6f9fc;
  --bs-primary-rgb: 93, 135, 255;
  --bs-secondary-rgb: 73, 190, 255;
  --bs-success-rgb: 19, 222, 185;
  --bs-info-rgb: 83, 155, 255;
  --bs-warning-rgb: 255, 174, 31;
  --bs-danger-rgb: 250, 137, 107;
  --bs-light-rgb: 246, 249, 252;
  --bs-dark-rgb: 42, 53, 71;
  --bs-muted-rgb: 90, 106, 133;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-light-primary-rgb: 236, 242, 255;
  --bs-light-secondary-rgb: 232, 247, 255;
  --bs-light-info-rgb: 235, 243, 254;
  --bs-light-success-rgb: 230, 255, 250;
  --bs-light-warning-rgb: 254, 245, 229;
  --bs-light-danger-rgb: 251, 242, 239;
  --bs-light-indigo-rgb: 235, 243, 254;
  --bs-dark-light-rgb: 42, 53, 71;
  --bs-light-gray-rgb: 246, 249, 252;
  --bs-primary-text-emphasis: #253666;
  --bs-secondary-text-emphasis: #1d4c66;
  --bs-success-text-emphasis: #08594a;
  --bs-info-text-emphasis: #213e66;
  --bs-warning-text-emphasis: #66460c;
  --bs-danger-text-emphasis: #64372b;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #dfe7ff;
  --bs-secondary-bg-subtle: #dbf2ff;
  --bs-success-bg-subtle: #d0f8f1;
  --bs-info-bg-subtle: #ddebff;
  --bs-warning-bg-subtle: #ffefd2;
  --bs-danger-bg-subtle: #fee7e1;
  --bs-light-bg-subtle: #fbfcfe;
  --bs-dark-bg-subtle: #7c8fac;
  --bs-primary-border-subtle: #becfff;
  --bs-secondary-border-subtle: #b6e5ff;
  --bs-success-border-subtle: #a1f2e3;
  --bs-info-border-subtle: #bad7ff;
  --bs-warning-border-subtle: #ffdfa5;
  --bs-danger-border-subtle: #fdd0c4;
  --bs-light-border-subtle: #eaeff4;
  --bs-dark-border-subtle: #5a6a85;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Plus Jakarta Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #5a6a85;
  --bs-body-color-rgb: 90, 106, 133;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(90, 106, 133, 0.75);
  --bs-secondary-color-rgb: 90, 106, 133;
  --bs-secondary-bg: #eaeff4;
  --bs-secondary-bg-rgb: 234, 239, 244;
  --bs-tertiary-color: rgba(90, 106, 133, 0.5);
  --bs-tertiary-color-rgb: 90, 106, 133;
  --bs-tertiary-bg: #f6f9fc;
  --bs-tertiary-bg-rgb: 246, 249, 252;
  --bs-heading-color: #2a3547;
  --bs-link-color: #5d87ff;
  --bs-link-color-rgb: 93, 135, 255;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4a6ccc;
  --bs-link-hover-color-rgb: 74, 108, 204;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #ffefd2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #ebf1f6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 7px;
  --bs-border-radius-sm: 5px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 12px;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: unset;
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(93, 135, 255, 0.25);
  --bs-form-valid-color: #13deb9;
  --bs-form-valid-border-color: #13deb9;
  --bs-form-invalid-color: #fa896b;
  --bs-form-invalid-border-color: #fa896b;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-secondary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-info-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-success-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-indigo-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-dark-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-light-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-light-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-gray-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-light-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-light-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-light-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-info-rgb), var(--bs-text-opacity)) !important;
}

.text-light-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-success-rgb), var(--bs-text-opacity)) !important;
}

.text-light-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-light-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}