.salary {
  display: grid;
  grid-template-columns: 50% 48%;
  grid-gap: 30px;
  background-color: #343a40;
  padding-top: 100px;
  padding-bottom: 100px;
}

.salary__title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 8px;
}

.salary__text {
  color: #fff;
}

.salary__right {
  display: flex;
  align-items: center;
}

.salary__amount {
  font-size: 75px;
  font-weight: 500;
  color: #fff;
}

.salary__average {
  color: #fff;
}

.salary__piechart {
  margin-right: 10px;
  margin-top: 10px;
}

.salary__piechart > * {
}

@media screen and (max-width: 768px) {
  .salary {
    display: block;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .salary__right {
    margin-top: 20px;
  }

  .salary__amount {
    font-size: 40px;
  }
}
