.logo-label {
  font-size: 27px;
  letter-spacing: 1px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .logo img {
    height: 30px;
  }
}
