.lesson-layout {
  display: flex;
}
.lesson-content {
  flex: 1;
}
.lesson__navigation {
  width: 190px;
  margin-left: 16px;
  padding-left: 16px;
}

.lesson__navigation__menu ul li a {
  line-height: 30px;
}
.lesson img {
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .lesson-layout {
    display: flex;
    flex-direction: column-reverse;
  }

  .lesson__navigation {
    border-left: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    width: 100%;
  }
}
