.skills__title {
  margin-bottom: 40px;
  font-size: 32px;
  line-height: 60px;
  font-weight: 600;
  margin-top: 0;
}
.skills__list{
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
}

.skills__subtitle{
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}


@media screen and (max-width: 768px) {
  .skills__list{
    display: block;
  }

  .skills__item{
    margin-bottom: 36px;
  }
}