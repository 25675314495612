section {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
