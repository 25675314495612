.certificate {
  padding-top: 50px;
  padding-bottom: 50px;
}
.certificate__image {
  text-align: center;
}

.certificate__image > img {
  width: 300px;
}

@media screen and (max-width: 768px) {
  .certificate__image {
    width: 100%;
  }
}
