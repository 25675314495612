
$google-blue: #4285f4;

.login-form-wrapper {
  margin: 0 auto;
  width: 500px;
}

.firebase-sign-in{
  margin-top: 2rem;
  
  & > button{
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;    
  }
}

.email-sigin-button{
  width: 100%;
}