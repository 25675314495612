.app-pagination {
  display: flex;
  gap: 8px;
  margin: 2rem 0;
  justify-content: center;
}

.app-pagination__item {
  font-size: 20px;
  cursor: pointer;
}
