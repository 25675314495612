@import "../variables.scss";

.m {
  @each $i in $space-list {
    &-#{$i} {
      margin: #{$i}px;
    }

    @each $shortPosition, $longPosition in $sides {
      &-#{$shortPosition}-#{$i} {
        margin-#{$longPosition}: #{$i}px;
      }
    }
  }
}

.m-auto {
  margin: auto;
}

.m-l-auto {
  margin-left: auto;
}
.m-r-auto {
  margin-right: auto;
}
