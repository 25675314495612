.border-start {
  border-left: 1px solid #dee2e6 !important;
}
.border-end {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}
