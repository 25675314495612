@import '../../scss/variables.scss';

.app-button {
  color: #fff;
  background-color: rgb(29, 29, 29);
  padding: 10px 40px;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: 300;
  border-radius: 60px;
  line-height: 16px;
  height: fit-content;
  cursor: pointer;

  &:hover {
    background-color: map-get($map: $colors, $key: "red");
    color: #fff;
    border-color: #fff;
  }

  &:disabled{
    background-color: gray;
    border: gray;
  }
}

@media screen and (max-width: 768px) {
    .app-button{
        padding: 10px 15px;
    }
}