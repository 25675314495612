.course-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-block .course-block{
  flex: 1;
}

.course-block__start {
  font-size: 22px;
  margin-bottom: 40px;
  line-height: 26px;
  color: #a60a3d;
}

.course-block__image {
  height: 500px;
}

.course-block__image img {
  width: auto;
  height: 100%;
}

.course-block__start-wrapper {
  display: flex;
}

.course-block__button-group {
  display: flex;
}

.course__name {
  margin-bottom: 16px;
  font-family: Borsch, sans-serif;
  color: #1d1d1d;
  font-size: 44px;
  line-height: 60px;
  font-weight: 400;
}

.course-start-note {
  margin-top: 10px;
  color: #606060;
  font-size: 14px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.cursor-blink {
  animation: blink 1s linear infinite;
}

@media screen and (max-width: 768px) {
  .course-block {
    display: block;
  }

  .course-block__button-group {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .app-button {
    width: fit-content;
    margin-bottom: 15px;
  }

  .course__name {
    text-align: center;
  }
  .course-start-note {
    margin-bottom: 15px;
  }
  .course-block__image {
    text-align: center;
  }
}
