.show-counter{
    display: flex;
    margin-left: 20px;
}
.counter__item{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    align-items: center;
}

.counter__amount{
    font-size: 18px;
}

.counter__unit{
    font-size: 14px;
}