input {
    padding: 5px;
}

.form-input {
    margin: 16px 0;
}

.form-input__label {
    margin-bottom: 4px;
    font-size: 14px;
    opacity: 0.6;
}