.course-stats__lesson {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 8px 0;
}

.course-stats__header {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 16px 0;
  font-size: 18px;
  font-weight: 500;
}

.course-stats {
}

.course-stats__course {
  margin-bottom: 32px;

  & > h3 {
    margin-bottom: 16px;
  }
}
