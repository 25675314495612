.course-lessons {
    border: 1px solid #ddd;
    border-radius: 5px;
}

.course-lesson{
    border-bottom: 1px solid #ddd;
}
.lesson-number{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}